import { Brand } from "data-access/repositories/brand";

export type PermissionSetId = Brand<number, "PermissionSetId">;

export interface PermissionSet {
  id: PermissionSetId;
  name: string;
  displayOrder: number;
  roleType: "admin" | "normal" | "maintainer" | "free";
  projectWrite: boolean;
  projectRead: "forbidden" | "only_created" | "only_assigned" | "all";
  projectReadI18n: "不可" | "作成した案件" | "招待された案件" | "すべて";
  salesWrite: boolean;
  salesRead: boolean;
  costWrite: boolean;
  costRead: boolean;
  estimateWrite: "forbidden" | "except_amount" | "all";
  estimateWriteI18n: "不可" | "金額以外" | "すべて";
  estimateRead: boolean;
  attachmentRead: "forbidden" | "only_published" | "all";
  attachmentReadI18n: "アップロードしたファイルのみ" | "ファイルごとの設定" | "すべて";
  billingWrite: boolean;
  billingRead: boolean;
  scheduleWrite: "forbidden" | "only_created" | "all";
  scheduleWriteI18n: "不可" | "予定の作成と作成した予定の編集" | "予定の作成とすべての予定の編集";
  scheduleRead: "forbidden" | "only_assigned" | "all";
  scheduleReadI18n: "不可" | "招待された・作成した予定" | "すべて";
  workReportWrite: "forbidden" | "only_assigned" | "all";
  workReportWriteI18n: "不可" | "招待された案件" | "すべて";
  workReportRead: "forbidden" | "only_assigned" | "all";
  workReportReadI18n: "不可" | "招待された案件" | "すべて";
  clientWrite: boolean;
  clientRead: boolean;
  laborWrite: boolean;
  laborRead: boolean;
  masterWrite: boolean;
  masterRead: boolean;
}

export interface PermissionSetUpdateRequest {
  name?: string;
  displayOrder?: number;
  projectWrite?: boolean;
  projectRead?: "forbidden" | "only_assigned" | "only_created" | "all";
  salesWrite?: boolean;
  salesRead?: boolean;
  costWrite?: boolean;
  costRead?: boolean;
  estimateWrite?: "forbidden" | "except_amount" | "all";
  estimateRead?: boolean;
  attachmentRead?: "forbidden" | "only_published" | "all";
  billingWrite?: boolean;
  billingRead?: boolean;
  scheduleWrite?: "forbidden" | "only_created" | "all";
  scheduleRead?: "forbidden" | "only_assigned" | "all";
  workReportWrite?: "forbidden" | "only_assigned" | "all";
  workReportRead?: "forbidden" | "only_assigned" | "all";
  clientWrite?: boolean;
  clientRead?: boolean;
  laborWrite?: boolean;
  laborRead?: boolean;
  masterWrite?: boolean;
  masterRead?: boolean;
}

export const initialPermissionSet: PermissionSet = {
  id: 0 as PermissionSetId,
  name: "",
  displayOrder: 0,
  roleType: "free",
  projectWrite: false,
  projectRead: "forbidden",
  projectReadI18n: "不可",
  salesWrite: false,
  salesRead: false,
  costWrite: false,
  costRead: false,
  estimateWrite: "forbidden",
  estimateWriteI18n: "不可",
  estimateRead: false,
  attachmentRead: "forbidden",
  attachmentReadI18n: "アップロードしたファイルのみ",
  billingWrite: false,
  billingRead: false,
  scheduleWrite: "forbidden",
  scheduleWriteI18n: "不可",
  scheduleRead: "forbidden",
  scheduleReadI18n: "不可",
  workReportWrite: "forbidden",
  workReportWriteI18n: "不可",
  workReportRead: "forbidden",
  workReportReadI18n: "不可",
  clientWrite: false,
  clientRead: false,
  laborWrite: false,
  laborRead: false,
  masterWrite: false,
  masterRead: false,
};
