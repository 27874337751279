import { Brand } from "data-access/repositories/brand";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { User } from "data-access/repositories/user/user.dto";
import {
  ClientServiceReportFormat,
  ClientServiceReportFormatId,
} from "./client_service_report_format.dto";
import { ClientServiceReportPageResponse } from "./client_service_report_page.dto";

export type ClientServiceReportId = Brand<number, "ClientServiceReportId">;

export interface ClientServiceReportIndex {
  id: ClientServiceReportId;
  format: {
    id: number;
    name: string;
  };
  urlKey: string;
  createdDate: string;
  createdBy: User;
}
export type ClientServiceReportIndexResponse = Array<ClientServiceReportIndex>;

export interface ClientServiceReportRequest {
  ClientServiceReportFormatId: number;
  projectId: ProjectId;
}

export interface ClientServiceReportResponse {
  id: ClientServiceReportId;
  urlKey: string;
  format: ClientServiceReportFormat;
  formatPages: ClientServiceReportFormatPage[];
}

export interface ClientServiceReportFormatPage {
  id: number;
  excelSheetName: string;
  isDynamic: boolean;
  pages: ClientServiceReportPageResponse[];
}

export type InputType = "text_field" | "text_area" | "date" | "image" | "check_box" | "select_box";

export const initialClientServiceReportResponse: ClientServiceReportResponse = {
  id: 0 as ClientServiceReportId,
  urlKey: "",
  format: {
    id: 0 as ClientServiceReportFormatId,
    name: "",
    pages: [
      {
        id: 0,
        excelSheetName: "",
      },
    ],
  },
  formatPages: [],
};
