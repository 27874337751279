import { useCallback, useMemo } from "react";
import { OpenInNew } from "@mui/icons-material";
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRowModel } from "@mui/x-data-grid-pro";
import { CustomizedDataGridPro } from "components/templates/customized-data-grid-pro";
import {
  CompanyUser,
  CompanyUserId,
  ScheduleOverlappingType,
  scheduleOverlappingTypes,
  ScheduleOverlappingTypeValue,
} from "data-access/repositories/company_user/company_user.dto";
import { companyUserRepository } from "data-access/repositories/company_user/company_user.repository";
import { Group, GroupId } from "data-access/repositories/group/group.dto";
import { groupRepository } from "data-access/repositories/group/group.repository";
import { theme } from "extensions/theme";
import { attendanceRuleRepository } from "features/company-settings/api/attendance/rule/attendance-rule.repository";
import {
  PermissionSet,
  PermissionSetId,
} from "features/company-settings/api/permission/permission_set.dto";
import { permissionSetRepository } from "features/company-settings/api/permission/permission_set.repositories";
import {
  AttendanceRule,
  AttendanceRuleId,
} from "features/company-settings/types/attendance/rule/attendance_rule.dto";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";
import { API_PATHS } from "utils/apiPaths";
import { handleReduxError } from "utils/errorHandler";
import { openURLInNewTab } from "utils/openURLInNewTab";
import { styles } from "./styles";

const menuItemStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 24,
  width: "min-content",
  px: "10px",
  fontWeight: "bold",
  fontSize: "12px",
  borderRadius: "4px",
};

export const EmployeeTable = () => {
  const dispatch = useAppDispatch();
  const classes = styles();

  const {
    mutate: membersMutate,
    data: members,
    isValidating,
  } = useSWR("/api/v1/company_users", companyUserRepository.index);
  const { mutate: groupsMutate, data: groups } = useSWR("/api/v1/groups", groupRepository.index);
  const { data: attendanceRules } = useSWR(
    "/api/v1/attendance/rules",
    attendanceRuleRepository.index,
    { revalidateOnFocus: false },
  );
  const { data: permissionSets } = useSWR(
    API_PATHS.getPermissionSets(),
    permissionSetRepository.index,
  );
  const hoge = useMemo(() => {
    if (!permissionSets) return [];
    return permissionSets.filter(
      (permissionSet) =>
        !(permissionSet.roleType === "maintainer" || permissionSet.roleType === "free"),
    );
  }, [permissionSets]);

  const activeEmployees = members?.filter((member) => {
    const isInHouseDeactivateMember = !member.isOutsourcedMember && member.isDeactivate;
    const isOutsourcedMember = member.isOutsourcedMember;
    return !(isInHouseDeactivateMember || isOutsourcedMember);
  });

  const handleChangeGroup = async (
    e: SelectChangeEvent<number | GroupId>,
    companyUserId: CompanyUserId,
  ) => {
    const { value: groupId } = e.target;

    try {
      await companyUserRepository.update(companyUserId, {
        groupId: Number(groupId),
      });
      members && membersMutate();
      groups && groupsMutate();
      dispatch(mainOperations.updateSuccessMessage("グループを変更しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  const handleChangeScheduleOverlappingType = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
    user_id: CompanyUserId,
  ) => {
    const value = e.target.value;
    try {
      await companyUserRepository.update(user_id, {
        scheduleOverlappingType: value as ScheduleOverlappingTypeValue,
      });
      members && membersMutate();
      dispatch(mainOperations.updateSuccessMessage("予定重複時の設定を変更しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "予定重複時の設定の変更に失敗しました");
    }
  };

  const handleChangePermissionSet = async (
    e: SelectChangeEvent<PermissionSetId>,
    user_id: CompanyUserId,
  ) => {
    const value = Number(e.target.value) as PermissionSetId;
    try {
      await companyUserRepository.update(user_id, {
        permissionSetId: value,
      });
      membersMutate();
      dispatch(mainOperations.updateSuccessMessage("権限を変更しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "権限の変更に失敗しました");
    }
  };

  const handleEdit = useCallback(async (params: GridRowModel) => {
    try {
      await companyUserRepository.update(params.id, {
        name: params.name,
        unitPricePerDay: params.unit_price_per_day,
      });
      membersMutate();
      dispatch(mainOperations.updateSuccessMessage("メンバーを更新しました"));
      dispatch(mainOperations.getUsers());
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
    return params;
  }, []);

  const handleChangeAttendanceRule = async (
    e: SelectChangeEvent<number | AttendanceRule>,
    companyUserId: CompanyUserId,
  ) => {
    dispatch(mainOperations.updateIsLoading(true));
    const { value: attendanceRuleId } = e.target;

    try {
      await companyUserRepository.update(companyUserId, {
        attendanceRuleId: attendanceRuleId as AttendanceRuleId,
      });
      members && membersMutate();
      dispatch(mainOperations.updateSuccessMessage("勤怠ルールを変更しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "勤怠ルールの変更に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const columns = () => {
    const headers: GridColDef[] = [];
    headers.push({
      field: "name",
      headerName: "名前",
      minWidth: 240,
      sortable: false,
      disableColumnMenu: true,
      editable: true,
      renderCell: (params: GridRenderCellParams<CompanyUser>) => {
        return (
          <Tooltip title="クリックで名前を編集できます" placement="top-start">
            <span>{params.value}</span>
          </Tooltip>
        );
      },
    });
    headers.push({
      field: "group_id",
      headerName: "グループ",
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<CompanyUser>) => {
        return (
          <>
            <Select
              id="group"
              name="group"
              className={classes.tableSelectBox}
              value={params.row.groupId || 0}
              variant="standard"
              onChange={(e) => handleChangeGroup(e, params.row.id)}
              displayEmpty
              sx={{
                "&:hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "none" },
              }}
            >
              <MenuItem value={0}>
                <Box
                  sx={{
                    ...menuItemStyles,
                    border: `1px solid ${theme.palette.grayScale[900]} `,
                  }}
                >
                  未設定
                </Box>
              </MenuItem>
              {groups &&
                groups.map((group: Group, index: number) => (
                  <MenuItem value={group.id} key={index}>
                    <Box
                      sx={{
                        ...menuItemStyles,
                        backgroundColor: `#${group.color_number}`,
                      }}
                    >
                      {group.name}
                    </Box>
                  </MenuItem>
                ))}
            </Select>
          </>
        );
      },
    });
    headers.push({
      field: "unit_price_per_day",
      headerName: "人工単価",
      minWidth: 100,
      editable: true,
      sortable: false,
      align: "right",
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<CompanyUser>) => (
        <>
          <Typography sx={{ fontSize: "14px", mr: "2px" }}>¥</Typography>
          <Typography fontSize="14px">{params.row.unitPricePerDay.toLocaleString()}</Typography>
        </>
      ),
    });
    headers.push({
      field: "attendance_rule",
      headerName: "勤怠ルール",
      minWidth: 150,
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<CompanyUser>) => (
        <>
          <Select
            name="attendanceRule"
            className={classes.tableSelectBox}
            value={params.row.attendanceRule.id || 0}
            variant="standard"
            onChange={(e) => handleChangeAttendanceRule(e, params.row.id)}
            displayEmpty
            sx={{
              "&:hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "none" },
            }}
          >
            {attendanceRules &&
              attendanceRules.map((rule: AttendanceRule, index: number) => (
                <MenuItem value={rule.id} key={index}>
                  <Box sx={{ ...menuItemStyles }}>{rule.name}</Box>
                </MenuItem>
              ))}
          </Select>
        </>
      ),
    });
    headers.push({
      field: "schedule_overlapping_type",
      headerName: "予定重複時",
      minWidth: 180,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<CompanyUser>) => {
        return (
          <Select
            id="schedule_overlapping_type"
            name="schedule_overlapping_type"
            className={classes.tableSelectBox}
            value={params.row.scheduleOverlappingType.value || ""}
            variant="standard"
            onChange={(e) => handleChangeScheduleOverlappingType(e, params.row.id)}
            displayEmpty
            sx={{
              "&:hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "none" },
            }}
          >
            {scheduleOverlappingTypes.map(
              (scheduleOverlappingType: ScheduleOverlappingType, index: number) => (
                <MenuItem value={scheduleOverlappingType.value} key={index}>
                  <Box sx={{ ...menuItemStyles }}>{scheduleOverlappingType.valueI18n}</Box>
                </MenuItem>
              ),
            )}
          </Select>
        );
      },
    });
    headers.push({
      field: "permission_set",
      headerName: "権限",
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box sx={{ display: "flex", alignItems: "center", fontWeight: "500" }}>
          権限
          <IconButton
            onClick={() => openURLInNewTab("company-settings/permission-settings")}
            size="small"
          >
            <OpenInNew fontSize="small" color="primary" />
          </IconButton>
        </Box>
      ),
      renderCell: (params: GridRenderCellParams<CompanyUser>) => {
        return (
          <Select
            name="permissionSet"
            className={classes.tableSelectBox}
            value={params.row.permissionSet.id}
            variant="standard"
            onChange={(e) => handleChangePermissionSet(e, params.row.id)}
            displayEmpty
            sx={{
              "&:hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "none" },
            }}
          >
            {hoge.map((permissionSet: PermissionSet, index: number) => (
              <MenuItem key={index} value={permissionSet.id}>
                <Box sx={{ ...menuItemStyles }}>{permissionSet.name}</Box>
              </MenuItem>
            ))}
          </Select>
        );
      },
    });
    return headers;
  };

  return (
    <>
      <CustomizedDataGridPro
        columnHeaderHeight={40}
        columns={columns()}
        rows={activeEmployees || []}
        loading={isValidating}
        hideFooter
        disableRowSelectionOnClick
        processRowUpdate={handleEdit}
      />
    </>
  );
};
