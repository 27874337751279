import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import {
  GRID_REORDER_COL_DEF,
  GridColDef,
  GridRenderCellParams,
  GridRowClassNameParams,
  GridRowOrderChangeParams,
  GridRowParams,
} from "@mui/x-data-grid-pro";
import { CustomizedDataGridPro } from "components/templates/customized-data-grid-pro";
import { PermissionSet } from "features/company-settings/api/permission/permission_set.dto";
import { permissionSetRepository } from "features/company-settings/api/permission/permission_set.repositories";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";
import { handleReduxError } from "utils/errorHandler";
import { styles } from "./styles";

export const PermissionSettingTable = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const classes = styles();

  const { data, isValidating } = useSWR("/api/v1/permission_sets", permissionSetRepository.index, {
    revalidateOnFocus: false,
  });

  const permissionSets = useMemo(() => {
    if (!data) return [];
    return data
      .filter((permissionSet) => permissionSet.roleType !== "maintainer")
      .sort((a, b) => {
        if (a.roleType === "admin" && b.roleType !== "admin") return -1;
        if (a.roleType !== "admin" && b.roleType === "admin") return 1;
        return a.displayOrder - b.displayOrder;
      });
  }, [data]);

  const handleRowClick = (params: GridRowParams) => {
    navigate(`/company-settings/permission-settings/${params.row.id}`, {
      state: { id: params.row.id },
    });
  };

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    try {
      await permissionSetRepository.update(params.row.id, {
        displayOrder: params.targetIndex,
      });
      dispatch(mainOperations.updateSuccessMessage("順番を変更しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "順番の変更に失敗しました");
    }
  };

  // 「管理者」の行のスタイル
  const getRowClassName = (params: GridRowClassNameParams) => {
    return params.row.roleType === "admin" ? classes.noDrag : "";
  };

  return (
    <CustomizedDataGridPro
      rows={permissionSets}
      columns={columns()}
      loading={isValidating}
      hideFooter
      rowReordering
      onRowOrderChange={handleRowOrderChange}
      onRowClick={handleRowClick}
      getRowClassName={getRowClassName}
      pinnedColumns={{ left: [GRID_REORDER_COL_DEF.field, "name"] }}
    />
  );
};

const columns = () => {
  const headers: GridColDef[] = [];
  headers.push({
    field: "name",
    headerName: "権限名",
    minWidth: 240,
    sortable: false,
    disableColumnMenu: true,
  });
  headers.push({
    field: "project_write",
    headerName: "案件の作成",
    minWidth: 100,
    sortable: false,
    align: "center",
    renderCell: (params: GridRenderCellParams<PermissionSet>) =>
      params.row.projectWrite ? <CheckIcon /> : "",
  });
  headers.push({
    field: "projectReadI18n",
    headerName: "案件の閲覧",
    minWidth: 180,
    sortable: false,
    disableColumnMenu: true,
    align: "left",
  });
  headers.push({
    field: "attachmentReadI18n",
    headerName: "添付ファイルの閲覧",
    minWidth: 220,
    sortable: false,
    disableColumnMenu: true,
    align: "left",
  });
  headers.push({
    field: "salesWrite",
    headerName: "売上の編集",
    minWidth: 100,
    sortable: false,
    disableColumnMenu: true,
    align: "center",
    renderCell: (params: GridRenderCellParams<PermissionSet>) =>
      params.row.salesWrite ? <CheckIcon /> : "",
  });
  headers.push({
    field: "salesRead",
    headerName: "売上の閲覧",
    minWidth: 100,
    sortable: false,
    disableColumnMenu: true,
    align: "center",
    renderCell: (params: GridRenderCellParams<PermissionSet>) =>
      params.row.salesRead ? <CheckIcon /> : "",
  });
  headers.push({
    field: "estimateWriteI18n",
    headerName: "見積書の編集",
    minWidth: 180,
    sortable: false,
    disableColumnMenu: true,
    align: "left",
  });
  headers.push({
    field: "estimateRead",
    headerName: "見積書の閲覧",
    minWidth: 120,
    sortable: false,
    disableColumnMenu: true,
    align: "center",
    renderCell: (params: GridRenderCellParams<PermissionSet>) =>
      params.row.estimateRead ? <CheckIcon /> : "",
  });
  headers.push({
    field: "billingWrite",
    headerName: "請求の作成",
    minWidth: 100,
    sortable: false,
    disableColumnMenu: true,
    align: "center",
    renderCell: (params: GridRenderCellParams<PermissionSet>) =>
      params.row.billingWrite ? <CheckIcon /> : "",
  });
  headers.push({
    field: "billingRead",
    headerName: "請求の閲覧",
    minWidth: 100,
    sortable: false,
    disableColumnMenu: true,
    align: "center",
    renderCell: (params: GridRenderCellParams<PermissionSet>) =>
      params.row.billingRead ? <CheckIcon /> : "",
  });
  headers.push({
    field: "scheduleWriteI18n",
    headerName: "予定の作成・編集",
    minWidth: 240,
    sortable: false,
    disableColumnMenu: true,
    align: "left",
  });
  headers.push({
    field: "scheduleReadI18n",
    headerName: "予定の閲覧",
    minWidth: 220,
    sortable: false,
    disableColumnMenu: true,
    align: "left",
  });
  headers.push({
    field: "workReportWriteI18n",
    headerName: "作業日報の作成",
    minWidth: 140,
    sortable: false,
    disableColumnMenu: true,
    align: "left",
  });
  headers.push({
    field: "workReportReadI18n",
    headerName: "作業日報の閲覧",
    minWidth: 200,
    sortable: false,
    disableColumnMenu: true,
    align: "left",
  });
  headers.push({
    field: "clientWrite",
    headerName: "顧客の作成",
    minWidth: 100,
    sortable: false,
    disableColumnMenu: true,
    align: "center",
    renderCell: (params: GridRenderCellParams<PermissionSet>) =>
      params.row.clientWrite ? <CheckIcon /> : "",
  });
  headers.push({
    field: "clientRead",
    headerName: "顧客の閲覧",
    minWidth: 100,
    sortable: false,
    disableColumnMenu: true,
    align: "center",
    renderCell: (params: GridRenderCellParams<PermissionSet>) =>
      params.row.clientRead ? <CheckIcon /> : "",
  });
  headers.push({
    field: "laborWrite",
    headerName: "労務管理の編集",
    minWidth: 120,
    sortable: false,
    disableColumnMenu: true,
    align: "center",
    renderCell: (params: GridRenderCellParams<PermissionSet>) =>
      params.row.laborWrite ? <CheckIcon /> : "",
  });
  headers.push({
    field: "laborRead",
    headerName: "労務管理の閲覧",
    minWidth: 120,
    sortable: false,
    disableColumnMenu: true,
    align: "center",
    renderCell: (params: GridRenderCellParams<PermissionSet>) =>
      params.row.laborRead ? <CheckIcon /> : "",
  });
  headers.push({
    field: "masterWrite",
    headerName: "会社設定の編集",
    minWidth: 120,
    sortable: false,
    disableColumnMenu: true,
    align: "center",
    renderCell: (params: GridRenderCellParams<PermissionSet>) =>
      params.row.masterWrite ? <CheckIcon /> : "",
  });
  headers.push({
    field: "masterRead",
    headerName: "会社設定の閲覧",
    minWidth: 120,
    sortable: false,
    disableColumnMenu: true,
    align: "center",
    renderCell: (params: GridRenderCellParams<PermissionSet>) =>
      params.row.masterRead ? <CheckIcon /> : "",
  });
  return headers;
};
