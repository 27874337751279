import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { Layout } from "components/templates/layout";
import { PermissionSettingSidebar } from "./sidebar";
import { PermissionSettingTable } from "./table";

export const PermissionSetting = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

  useEffect(() => {
    if (new RegExp(`^/company-settings/permission-settings/\\d+$`).test(location.pathname)) {
      setIsSidebarOpen(true);
    }
  }, [location]);

  const handleSidebarClose = () => {
    navigate("/company-settings/permission-settings");
    setIsSidebarOpen(false);
  };

  return (
    <Layout>
      <Typography sx={{ fontWeight: "bold", fontSize: "24px", mb: "20px" }}>権限設定</Typography>
      <PermissionSettingSidebar isOpen={isSidebarOpen} onClose={handleSidebarClose} />
      <Box>
        <PermissionSettingTable />
      </Box>
    </Layout>
  );
};
