import { useMemo, useState } from "react";
import { Box, Button, Checkbox, FormControlLabel, TextField, Typography } from "@mui/material";
import { CustomModal } from "components/molecules/custom-modal";
import {
  CompanyUserCreateRequest,
  initialCompanyUserRequest,
} from "data-access/repositories/company_user/company_user.dto";
import { companyUserRepository } from "data-access/repositories/company_user/company_user.repository";
import { theme } from "extensions/theme";
import { permissionSetRepository } from "features/company-settings/api/permission/permission_set.repositories";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";
import { API_PATHS } from "utils/apiPaths";

interface Props {
  open: (e: boolean) => void;
  isOpen: boolean;
}

export const MemberFormModal = (props: Props) => {
  const dispatch = useAppDispatch();

  const { mutate } = useSWR("/api/v1/company_users", companyUserRepository.index);

  const [formState, setFormState] = useState<CompanyUserCreateRequest>(initialCompanyUserRequest);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const { data: permissionSets } = useSWR(
    API_PATHS.getPermissionSets(),
    permissionSetRepository.index,
  );
  const freePermissionSet = useMemo(() => {
    return permissionSets?.find((permissionSet) => permissionSet.roleType === "free");
  }, [permissionSets]);

  // 「アサインのみ可能にする」チェックボックスの設定
  const [isAssignMemberOnly, setIsAssignMemberOnly] = useState<boolean>(true);
  const handleAssignMemberOnlyCheckbox = (_: any, checked: boolean) => {
    if (!freePermissionSet) return;
    setIsAssignMemberOnly(checked);
    setFormState({
      ...formState,
      email: "",
      permissionSetId: checked ? null : freePermissionSet.id,
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormState({ ...formState, [e.target.name]: e.target.value as string });
  };

  const handleSubmit = async () => {
    setIsSubmitted(true);
    try {
      await companyUserRepository.create(formState);
      mutate();
      handleClose();
      dispatch(mainOperations.updateSuccessMessage("協力会社を作成しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    } finally {
      setIsSubmitted(false);
    }
  };

  const isValid = (): boolean => {
    if (!formState.name) return false;
    if (!isAssignMemberOnly && !formState.email) return false;
    return true;
  };

  const handleClose = () => {
    setFormState(initialCompanyUserRequest);
    setIsAssignMemberOnly(true);
    props.open(false);
    setIsSubmitted(false);
  };

  return (
    <CustomModal
      open={props.isOpen}
      onClose={handleClose}
      title="協力会社の新規作成"
      maxWidth="sm"
      footer={
        <>
          <Button variant="outlined" onClick={handleClose} sx={{ width: "10rem" }}>
            キャンセル
          </Button>
          <Button
            disabled={!isValid() || isSubmitted}
            onClick={handleSubmit}
            variant="contained"
            sx={{ width: "10rem", ml: "1rem" }}
          >
            保存
          </Button>
        </>
      }
    >
      <Box sx={{ marginTop: "20px" }}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "8px", gap: "8px" }}>
          <Typography sx={{ fontWeight: "bold" }}>名前</Typography>
          <Typography sx={{ color: theme.palette.red[500], fontSize: "14px", fontWeight: "500" }}>
            必須
          </Typography>
        </div>
        <TextField
          name="name"
          value={formState.name}
          onChange={handleChange}
          sx={{ width: "75%" }}
          placeholder="名前を入力"
        />
      </Box>

      <FormControlLabel
        sx={{ ml: 0, my: "12px" }}
        labelPlacement="end"
        label={
          <Typography sx={{ fontSize: "14px" }}>アサインのみ可能（ログイン不可）にする</Typography>
        }
        control={
          <Checkbox checked={isAssignMemberOnly} onChange={handleAssignMemberOnlyCheckbox} />
        }
      />

      <Box>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "8px", gap: "8px" }}>
          <Typography sx={{ fontWeight: "bold" }}>メールアドレス</Typography>
          {!isAssignMemberOnly && (
            <Typography sx={{ color: theme.palette.red[500], fontSize: "14px", fontWeight: "500" }}>
              必須
            </Typography>
          )}
        </div>
        <TextField
          required={!isAssignMemberOnly}
          disabled={isAssignMemberOnly}
          name="email"
          type="email"
          value={isAssignMemberOnly ? "" : formState.email}
          onChange={handleChange}
          sx={{
            width: "75%",
            backgroundColor: isAssignMemberOnly ? theme.palette.primary.light : "",
          }}
          placeholder="メールアドレスを入力"
        />
      </Box>
      <Typography sx={{ fontSize: "12px", color: theme.palette.grayScale[700], mt: "8px" }}>
        ユーザーIDとパスワードを記載したメールが送信されます。
      </Typography>
    </CustomModal>
  );
};
