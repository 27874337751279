import { theme } from "extensions/theme";

export const styles = {
  header: {
    position: "sticky",
    top: 0,
    pt: "70px",
    pb: "16px",
    backgroundColor: theme.palette.grayScale[0],
    zIndex: "2",
    boxShadow: "0px 5px 5px -5px rgba(0,0,0,0.5)",
    mb: "20px",
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "20px",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    p: 2,
    borderTop: 1,
    borderColor: "divider",
    bgcolor: "background.paper",
  },
  sectionTitle: {
    fontWeight: "bold",
    fontSize: "12px",
    color: theme.palette.grayScale[700],
    "& .MuiDivider-wrapper": {
      pl: 0,
    },
    "&::before": {
      width: 0,
    },
  },
  itemName: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  section: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};
