import { createStyles, makeStyles } from "@mui/styles";

export const styles = makeStyles(() =>
  createStyles({
    noDrag: {
      "&.MuiDataGrid-row .MuiDataGrid-rowReorderCellContainer .MuiDataGrid-rowReorderCell--draggable":
        {
          display: "none",
        },
    },
  }),
);
