import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import {
  CompanyUserIndexResponse,
  CompanyUserUpdateRequest,
  CompanyUserCreateRequest,
  CompanyUserId,
  CompanyUserIndexForScheduleRequest,
} from "./company_user.dto";
import { ForScheduleIndexResponse } from "./for_schedule_company_user.dto";
import { ApiClient } from "../../ApiClient";

export const companyUserRepository = {
  index(): Promise<CompanyUserIndexResponse> {
    return ApiClient.get("/api/v1/company_users/").then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  indexForSchedule(query: CompanyUserIndexForScheduleRequest): Promise<ForScheduleIndexResponse> {
    return ApiClient.get("/api/v1/company_users/for_schedule", {
      params: indexForScheduleParams(query),
    }).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  create(body: CompanyUserCreateRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post("/api/v1/company_users", createBody(body), config).then((res) => {
      return res.data;
    });
  },
  update(id: CompanyUserId, body: CompanyUserUpdateRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put(`/api/v1/company_users/${id}`, updateBody(body), config).then((res) => {
      return res.data;
    });
  },
};

const createBody = (body: CompanyUserCreateRequest) => {
  return {
    name: body.name,
    email: body.email,
    group_name: body.groupName,
    permission_set_id: body.permissionSetId,
  };
};

const updateBody = (body: CompanyUserUpdateRequest) => {
  return {
    name: body.name,
    group_id: body.groupId !== 0 ? body.groupId : null,
    unit_price_per_day: body.unitPricePerDay,
    display_order_position: body.displayOrderPosition && body.displayOrderPosition,
    is_deactivate: body.isDeactivate,
    schedule_overlapping_type: body.scheduleOverlappingType,
    attendance_rule_id: body.attendanceRuleId,
    permission_set_id: body.permissionSetId,
  };
};

const indexForScheduleParams = (query: CompanyUserIndexForScheduleRequest) => {
  return {
    start_time: query.startTime,
    end_time: query.endTime,
    exclude_schedule_id: query.excludeScheduleId,
  };
};
