import { ElementRef, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Close } from "@mui/icons-material";
import {
  Backdrop,
  CircularProgress,
  Box,
  Button,
  Divider,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DeleteIcon } from "components/icon/delete-icon";
import { DuplicateIcon } from "components/icon/duplicate-icon";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { DetailSidebar } from "components/templates/detail-sidebar";
import { theme } from "extensions/theme";
import {
  initialPermissionSet,
  PermissionSet,
  PermissionSetUpdateRequest,
} from "features/company-settings/api/permission/permission_set.dto";
import { permissionSetRepository } from "features/company-settings/api/permission/permission_set.repositories";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR, { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { handleReduxError } from "utils/errorHandler";
import { styles } from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
export const PermissionSettingSidebar = (props: Props) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const deleteConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);
  const [formState, setFormState] = useState<PermissionSetUpdateRequest>(initialPermissionSet);

  const {
    data,
    mutate: permissionSetsMutate,
    isValidating,
  } = useSWR(
    location.state?.id ? `/api/v1/permission_sets/${location.state.id}` : null,
    () => permissionSetRepository.show(location.state.id),
    {
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (!location.state?.id) return;
    if (data) {
      setFormState(data);
    }
  }, [data]);

  const handleChange = (field: string, value: any) => {
    setFormState((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleDuplicate = async () => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await permissionSetRepository.duplicate(location.state.id);
      mutate(API_PATHS.getPermissionSets());
      dispatch(mainOperations.updateSuccessMessage("権限を複製しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "権限の複製に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleDelete = async () => {
    if (!deleteConfirmRef.current) return;
    const res = await deleteConfirmRef.current.confirm();
    if (!res) return;

    dispatch(mainOperations.updateIsLoading(true));
    try {
      await permissionSetRepository.delete(location.state.id);
      mutate(API_PATHS.getPermissionSets());
      props.onClose();
      dispatch(mainOperations.updateSuccessMessage("権限を削除しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "権限の削除に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleSave = async () => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await permissionSetsMutate(
        async (currentData: PermissionSet | undefined) => {
          await permissionSetRepository.update(location.state.id, formState);
          return currentData ? { ...currentData, ...formState } : undefined;
        },
        { optimisticData: data ? { ...data, ...formState } : undefined, rollbackOnError: true },
      );
      mutate(API_PATHS.getPermissionSets());
      dispatch(mainOperations.updateSuccessMessage("権限を変更しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "権限の変更に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleReset = () => {
    setFormState(data || initialPermissionSet);
  };

  // 管理者権限か無料権限か
  const isRoleTypeAdminOrFree = data?.roleType === "admin" || data?.roleType === "free";

  return (
    <>
      <AsyncConfirmDialog ref={deleteConfirmRef} />

      <DetailSidebar isOpen={props.isOpen} onClose={props.onClose}>
        <Backdrop
          sx={{ color: theme.palette.grayScale[0], zIndex: () => 99 }}
          open={isValidating}
          invisible
        >
          <CircularProgress />
        </Backdrop>

        <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
          <Box sx={{ ...styles.header, width: "400px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
              <Button
                startIcon={<Close />}
                onClick={props.onClose}
                sx={{ color: theme.palette.grayScale[700] }}
              >
                とじる
              </Button>
              <Box>
                {isRoleTypeAdminOrFree ? (
                  <Typography
                    color="error"
                    sx={{ fontSize: "12px", fontWeight: "bold", textAlign: "right" }}
                  >
                    ※管理者権限および無料権限は
                    <br />
                    更新することができません
                  </Typography>
                ) : (
                  <>
                    <Button startIcon={<DuplicateIcon size={18} />} onClick={handleDuplicate}>
                      複製
                    </Button>
                    <Button
                      startIcon={<DeleteIcon size={18} />}
                      onClick={handleDelete}
                      color="error"
                    >
                      削除
                    </Button>
                  </>
                )}
              </Box>
            </div>

            <Typography sx={{ fontWeight: "bold", mb: "8px" }}>権限名</Typography>
            <TextField
              value={formState.name}
              onChange={(e) => handleChange("name", e.target.value)}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  height: "40px",
                },
              }}
              disabled={isRoleTypeAdminOrFree}
            />
          </Box>
          <Box
            sx={{
              flex: 1,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              pb: "100px",
            }}
          >
            <Divider textAlign="left" sx={{ ...styles.sectionTitle }}>
              案件
            </Divider>
            <Box sx={{ ...styles.section }}>
              <Typography sx={{ ...styles.itemName }}>案件の作成</Typography>
              <Switch
                checked={formState.projectWrite}
                onChange={(e) => handleChange("projectWrite", e.target.checked)}
                color="primary"
                disabled={isRoleTypeAdminOrFree}
              />
            </Box>
            <Box sx={{ ...styles.section }}>
              <Typography sx={{ ...styles.itemName }}>案件の閲覧</Typography>
              <Select
                value={formState.projectRead}
                onChange={(e) => handleChange("projectRead", e.target.value)}
                sx={{ width: "200px", height: "36px" }}
                disabled={isRoleTypeAdminOrFree}
              >
                <MenuItem value="all">すべて</MenuItem>
                <MenuItem value="only_assigned">招待された案件</MenuItem>
                <MenuItem value="only_created">作成した案件</MenuItem>
                <MenuItem value="forbidden">不可</MenuItem>
              </Select>
            </Box>
            <Box sx={{ ...styles.section }}>
              <Typography sx={{ ...styles.itemName }}>添付ファイルの閲覧</Typography>
              <Select
                value={formState.attachmentRead}
                onChange={(e) => handleChange("attachmentRead", e.target.value)}
                sx={{ width: "200px", height: "36px" }}
                disabled={isRoleTypeAdminOrFree}
              >
                <MenuItem value="all">すべて</MenuItem>
                <MenuItem value="only_published">ファイルごとの設定</MenuItem>
                <MenuItem value="forbidden">アップロードしたファイルのみ</MenuItem>
              </Select>
            </Box>

            <Divider textAlign="left" sx={{ ...styles.sectionTitle }}>
              売上
            </Divider>
            <Box sx={{ ...styles.section }}>
              <Typography sx={{ ...styles.itemName }}>売上の編集</Typography>
              <Switch
                checked={formState.salesWrite}
                onChange={(e) => handleChange("salesWrite", e.target.checked)}
                color="primary"
                disabled={isRoleTypeAdminOrFree}
              />
            </Box>
            <Box sx={{ ...styles.section }}>
              <Typography sx={{ ...styles.itemName }}>売上の閲覧</Typography>
              <Switch
                checked={formState.salesRead}
                onChange={(e) => handleChange("salesRead", e.target.checked)}
                color="primary"
                disabled={isRoleTypeAdminOrFree}
              />
            </Box>

            <Divider textAlign="left" sx={{ ...styles.sectionTitle }}>
              原価
            </Divider>
            <Box sx={{ ...styles.section }}>
              <Typography sx={{ ...styles.itemName }}>原価の編集</Typography>
              <Switch
                checked={formState.costWrite}
                onChange={(e) => handleChange("costWrite", e.target.checked)}
                color="primary"
                disabled={isRoleTypeAdminOrFree}
              />
            </Box>
            <Box sx={{ ...styles.section }}>
              <Typography sx={{ ...styles.itemName }}>原価の閲覧</Typography>
              <Switch
                checked={formState.costRead}
                onChange={(e) => handleChange("costRead", e.target.checked)}
                color="primary"
                disabled={isRoleTypeAdminOrFree}
              />
            </Box>

            <Divider textAlign="left" sx={{ ...styles.sectionTitle }}>
              見積書
            </Divider>
            <Box sx={{ ...styles.section }}>
              <Typography sx={{ ...styles.itemName }}>見積書の編集</Typography>
              <Select
                value={formState.estimateWrite}
                onChange={(e) => handleChange("estimateWrite", e.target.value)}
                sx={{ width: "200px", height: "36px" }}
                disabled={isRoleTypeAdminOrFree}
              >
                <MenuItem value="all">すべて</MenuItem>
                <MenuItem value="except_amount">金額以外</MenuItem>
                <MenuItem value="forbidden">不可</MenuItem>
              </Select>
            </Box>
            <Box sx={{ ...styles.section }}>
              <Typography sx={{ ...styles.itemName }}>見積書の閲覧</Typography>
              <Switch
                checked={formState.estimateRead}
                onChange={(e) => handleChange("estimateRead", e.target.checked)}
                color="primary"
                disabled={isRoleTypeAdminOrFree}
              />
            </Box>

            <Divider textAlign="left" sx={{ ...styles.sectionTitle }}>
              請求
            </Divider>
            <Box sx={{ ...styles.section }}>
              <Typography sx={{ ...styles.itemName }}>請求の編集</Typography>
              <Switch
                checked={formState.billingWrite}
                onChange={(e) => handleChange("billingWrite", e.target.checked)}
                color="primary"
                disabled={isRoleTypeAdminOrFree}
              />
            </Box>
            <Box sx={{ ...styles.section }}>
              <Typography sx={{ ...styles.itemName }}>請求の閲覧</Typography>
              <Switch
                checked={formState.billingRead}
                onChange={(e) => handleChange("billingRead", e.target.checked)}
                color="primary"
                disabled={isRoleTypeAdminOrFree}
              />
            </Box>

            <Divider textAlign="left" sx={{ ...styles.sectionTitle }}>
              予定
            </Divider>
            <Box sx={{ ...styles.section }}>
              <Typography sx={{ ...styles.itemName }}>予定の作成・編集</Typography>
              <Select
                value={formState.scheduleWrite}
                onChange={(e) => handleChange("scheduleWrite", e.target.value)}
                sx={{ width: "200px", height: "36px" }}
                disabled={isRoleTypeAdminOrFree}
              >
                <MenuItem value="all">予定の作成とすべての予定の編集</MenuItem>
                <MenuItem value="only_created">予定の作成と作成した予定の編集</MenuItem>
                <MenuItem value="forbidden">不可</MenuItem>
              </Select>
            </Box>
            <Box sx={{ ...styles.section }}>
              <Typography sx={{ ...styles.itemName }}>予定の閲覧</Typography>
              <Select
                value={formState.scheduleRead}
                onChange={(e) => handleChange("scheduleRead", e.target.value)}
                sx={{ width: "200px", height: "36px" }}
                disabled={isRoleTypeAdminOrFree}
              >
                <MenuItem value="all">すべて</MenuItem>
                <MenuItem value="only_assigned">招待された・作成した予定</MenuItem>
                <MenuItem value="forbidden">不可</MenuItem>
              </Select>
            </Box>

            <Divider textAlign="left" sx={{ ...styles.sectionTitle }}>
              作業日報
            </Divider>
            <Box sx={{ ...styles.section }}>
              <Typography sx={{ ...styles.itemName }}>作業日報の作成</Typography>
              <Select
                value={formState.workReportWrite}
                onChange={(e) => handleChange("workReportWrite", e.target.value)}
                sx={{ width: "200px", height: "36px" }}
                disabled={isRoleTypeAdminOrFree}
              >
                <MenuItem value="all">すべて</MenuItem>
                <MenuItem value="only_assigned">招待された案件</MenuItem>
                <MenuItem value="forbidden">不可</MenuItem>
              </Select>
            </Box>
            <Box sx={{ ...styles.section }}>
              <Typography sx={{ ...styles.itemName }}>作業日報の閲覧</Typography>
              <Select
                value={formState.workReportRead}
                onChange={(e) => handleChange("workReportRead", e.target.value)}
                sx={{ width: "200px", height: "36px" }}
                disabled={isRoleTypeAdminOrFree}
              >
                <MenuItem value="all">すべて</MenuItem>
                <MenuItem value="only_assigned">招待された案件</MenuItem>
                <MenuItem value="forbidden">不可</MenuItem>
              </Select>
            </Box>

            <Divider textAlign="left" sx={{ ...styles.sectionTitle }}>
              顧客
            </Divider>
            <Box sx={{ ...styles.section }}>
              <Typography sx={{ ...styles.itemName }}>顧客の作成</Typography>
              <Switch
                checked={formState.clientWrite}
                onChange={(e) => handleChange("clientWrite", e.target.checked)}
                color="primary"
                disabled={isRoleTypeAdminOrFree}
              />
            </Box>
            <Box sx={{ ...styles.section }}>
              <Typography sx={{ ...styles.itemName }}>顧客の閲覧</Typography>
              <Switch
                checked={formState.clientRead}
                onChange={(e) => handleChange("clientRead", e.target.checked)}
                color="primary"
                disabled={isRoleTypeAdminOrFree}
              />
            </Box>

            <Divider textAlign="left" sx={{ ...styles.sectionTitle }}>
              労務管理
            </Divider>
            <Box sx={{ ...styles.section }}>
              <Typography sx={{ ...styles.itemName }}>労務管理の編集</Typography>
              <Switch
                checked={formState.laborWrite}
                onChange={(e) => handleChange("laborWrite", e.target.checked)}
                color="primary"
                disabled={isRoleTypeAdminOrFree}
              />
            </Box>
            <Box sx={{ ...styles.section }}>
              <Typography sx={{ ...styles.itemName }}>労務管理の閲覧</Typography>
              <Switch
                checked={formState.laborRead}
                onChange={(e) => handleChange("laborRead", e.target.checked)}
                color="primary"
                disabled={isRoleTypeAdminOrFree}
              />
            </Box>

            <Divider textAlign="left" sx={{ ...styles.sectionTitle }}>
              会社設定
            </Divider>
            <Box sx={{ ...styles.section }}>
              <Typography sx={{ ...styles.itemName }}>会社設定の作成</Typography>
              <Switch
                checked={formState.masterWrite}
                onChange={(e) => handleChange("masterWrite", e.target.checked)}
                color="primary"
                disabled={isRoleTypeAdminOrFree}
              />
            </Box>
            <Box sx={{ ...styles.section }}>
              <Typography sx={{ ...styles.itemName }}>会社設定の閲覧</Typography>
              <Switch
                checked={formState.masterRead}
                onChange={(e) => handleChange("masterRead", e.target.checked)}
                color="primary"
                disabled={isRoleTypeAdminOrFree}
              />
            </Box>
          </Box>

          <Box sx={{ ...styles.footer }}>
            <Button
              onClick={handleReset}
              variant="text"
              sx={{ fontWeight: "bold" }}
              disabled={isRoleTypeAdminOrFree}
            >
              リセット
            </Button>
            <Button
              onClick={handleSave}
              variant="contained"
              sx={{ height: "40px" }}
              disabled={isRoleTypeAdminOrFree}
            >
              保存
            </Button>
          </Box>
        </Box>
      </DetailSidebar>
    </>
  );
};
