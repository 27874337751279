import {
  PermissionSet,
  PermissionSetId,
} from "features/company-settings/api/permission/permission_set.dto";
import {
  AttendanceRule,
  AttendanceRuleId,
} from "features/company-settings/types/attendance/rule/attendance_rule.dto";
import { Brand } from "../brand";
import { GroupId } from "../group/group.dto";
import { ScheduleId } from "../schedule/schedule.dto";
import { UserId } from "../user/user.dto";

export type CompanyUserId = Brand<number, "CompanyUserId">;

export interface CompanyUser {
  id: CompanyUserId;
  name: string;
  userId: UserId;
  groupId: GroupId;
  isOutsourcedMember: boolean;
  isSuperUser: boolean;
  hasEmail: boolean;
  isDeactivate: boolean;
  permissionSet: PermissionSet;
  unitPricePerDay: number;
  calendarStartDayOfWeek: DayOfWeek;
  numberOfProjectNoteLinesToDisplay: number;
  attendanceRule: AttendanceRule;
  scheduleOverlappingType: ScheduleOverlappingType;
}

export type CompanyUserIndexResponse = Array<CompanyUser>;

export type ScheduleOverlappingTypeValue = "forbidden" | "warning" | "can";

export interface ScheduleOverlappingType {
  value: ScheduleOverlappingTypeValue;
  valueI18n: string;
}

export const scheduleOverlappingTypes: ScheduleOverlappingType[] = [
  { value: "forbidden", valueI18n: "招待不可" },
  { value: "warning", valueI18n: "アラートあり" },
  { value: "can", valueI18n: "指定なし" },
];

export interface CompanyUserCreateRequest {
  name: string;
  email: string;
  permissionSetId: PermissionSetId | null;
  groupName?: string;
}

export interface CompanyUserIndexForScheduleRequest {
  startTime: string;
  endTime: string;
  excludeScheduleId?: ScheduleId;
}

export interface CompanyUserUpdateRequest {
  name?: string;
  groupId?: number;
  unitPricePerDay?: number;
  displayOrderPosition?: number;
  isDeactivate?: boolean;
  scheduleOverlappingType?: ScheduleOverlappingTypeValue;
  attendanceRuleId?: AttendanceRuleId;
  permissionSetId?: PermissionSetId;
}

// 月火水木金土日
export type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const initialCompanyUserRequest: CompanyUserCreateRequest = {
  name: "",
  email: "",
  permissionSetId: null,
};
