import React, {
  forwardRef,
  LegacyRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { DeleteIcon } from "components/icon/delete-icon";
import { PhotoResponse } from "data-access/repositories/project/photo/photo.dto";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { theme } from "extensions/theme";
import { ClientServiceReportFormatPage } from "features/client-service-report/types/client_service_report.dto";
import { ClientServiceReportItemResponse } from "features/client-service-report/types/client_service_report_item.dto";
import {
  ClientServiceReportHeadingResponse,
  ClientServiceReportPageId,
  ClientServiceReportPageResponse,
} from "features/client-service-report/types/client_service_report_page.dto";
import { ClientServiceReportItemsCount } from "utils/constant";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import { styles } from "./styles";
import { InputFieldSwitcher } from "../input-field-switcher";
import { PhotoSelectModal } from "../photo-select-modal";

interface Props {
  projectId: ProjectId;
  currentStepIndex: number;
  selectedLayoutName: string;
  formatPage: ClientServiceReportFormatPage;
  onChange: (newItem: ClientServiceReportItemResponse) => void;
  onAddPage: (index: number) => void;
  onDelete: (id: ClientServiceReportPageId) => void;
  onSelect: (photo: PhotoResponse) => void;
  onClick: (item: ClientServiceReportItemResponse) => void;
  onPhotoDelete: (item: ClientServiceReportItemResponse) => void;
}

export const FormatPageArea = (props: Props) => {
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState<boolean>(false);
  const isStaticPage: boolean = !props.formatPage.isDynamic;
  const scrollTopRef = useRef<HTMLDivElement>(null);

  // 目次のリスト押下時、該当箇所までスクロールさせたいため
  const refsMap = useMemo(() => {
    const newRefsMap: { [key: string]: React.RefObject<HTMLDivElement> } = {};

    props.formatPage.pages.forEach((page, pageIndex) => {
      page.headings.forEach((_, headingIndex) => {
        const key = isStaticPage
          ? `heading-${headingIndex}`
          : `heading-${pageIndex * ClientServiceReportItemsCount + headingIndex}`;
        newRefsMap[key] = React.createRef<HTMLDivElement>();
      });
    });
    return newRefsMap;
  }, [props.currentStepIndex, props.formatPage.pages]);

  useLayoutEffect(() => {
    scrollTopRef?.current?.scrollIntoView();
  }, [props.currentStepIndex]);

  const handleContentsClick = (key: string) => {
    const ref = refsMap[key];
    if (ref && ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <>
      <PhotoSelectModal
        isOpen={isPhotoModalOpen}
        onClose={() => setIsPhotoModalOpen(false)}
        projectId={props.projectId}
        onSelect={(photo) => {
          props.onSelect(convertKeysToCamelCase(photo) as PhotoResponse);
          setIsPhotoModalOpen(false);
        }}
      />

      <div style={{ display: "flex" }}>
        <SidebarContents
          isStaticPage={isStaticPage}
          sheetName={props.formatPage.excelSheetName}
          pages={props.formatPage.pages}
          onHeadingClick={handleContentsClick}
        />

        <MainContentArea
          {...props}
          formatPageId={props.formatPage.id}
          formatPageIndex={props.currentStepIndex}
          pages={props.formatPage.pages}
          isStaticPage={isStaticPage}
          refsMap={refsMap}
          scrollTopRef={scrollTopRef}
          onClickPhotoSelect={(item) => {
            props.onClick(item);
            setIsPhotoModalOpen(true);
          }}
          onPhotoDelete={props.onPhotoDelete}
        />
      </div>
    </>
  );
};

interface SidebarContentsProps {
  isStaticPage: boolean;
  sheetName: string;
  pages: ClientServiceReportPageResponse[];
  onHeadingClick: (key: string) => void;
}

const SidebarContents = ({
  isStaticPage,
  sheetName,
  pages,
  onHeadingClick,
}: SidebarContentsProps) => {
  const classes = styles();

  return (
    <div style={{ width: "20%", overflow: "scroll", height: "60vh" }}>
      {isStaticPage && <Typography sx={{ mb: "20px" }}>{sheetName}</Typography>}
      {isStaticPage ? (
        <>
          <Stack spacing="16px" sx={{ ml: "8px" }}>
            {pages.map((page) =>
              page.headings.map((heading, index) => (
                <Typography
                  key={heading.id}
                  className={classes.content}
                  onClick={() => onHeadingClick(`heading-${index}`)}
                  fontSize="14px"
                  sx={{ userSelect: "none" }}
                >
                  {heading.formatHeading.name}
                </Typography>
              )),
            )}
          </Stack>
        </>
      ) : (
        <>
          {pages.map((page, pageIndex) => (
            <div key={pageIndex} style={{ marginBottom: "24px" }}>
              <Typography sx={{ fontWeight: "500", mb: "16px" }}>ページ{pageIndex + 1}</Typography>
              <Stack spacing="14px" sx={{ ml: "8px" }}>
                {page.headings.map((heading, headingIndex) => {
                  const key = `heading-${pageIndex * ClientServiceReportItemsCount + headingIndex}`;
                  return (
                    <Typography
                      key={heading.id}
                      className={classes.content}
                      onClick={() => onHeadingClick(key)}
                      fontSize="14px"
                      sx={{ userSelect: "none" }}
                    >
                      {heading.formatHeading.name}
                    </Typography>
                  );
                })}
              </Stack>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

interface MainContentAreaProps {
  isStaticPage: boolean;
  formatPageId: number;
  formatPageIndex: number;
  pages: ClientServiceReportPageResponse[];
  selectedLayoutName: string;
  refsMap: { [key: string]: React.RefObject<HTMLDivElement> };
  scrollTopRef: LegacyRef<HTMLDivElement> | undefined;
  onChange: (newItem: ClientServiceReportItemResponse) => void;
  onAddPage: (index: number) => void;
  onDelete: (id: ClientServiceReportPageId) => void;
  onClickPhotoSelect: (item: ClientServiceReportItemResponse) => void;
  onPhotoDelete: (item: ClientServiceReportItemResponse) => void;
}

const MainContentArea = ({
  isStaticPage,
  formatPageId,
  formatPageIndex,
  pages,
  selectedLayoutName,
  onChange,
  onAddPage,
  onDelete,
  refsMap,
  scrollTopRef,
  onClickPhotoSelect,
  onPhotoDelete,
}: MainContentAreaProps) => {
  return (
    <div style={{ overflow: "scroll", height: "60vh", flex: 1 }}>
      <div ref={scrollTopRef} />
      {pages.map((page, pageIndex) => (
        <PageArea
          key={pageIndex}
          page={page}
          pageIndex={pageIndex}
          formatPageIndex={formatPageIndex}
          isStaticPage={isStaticPage}
          refsMap={refsMap}
          selectedLayoutName={selectedLayoutName}
          onChange={onChange}
          onClickPhotoSelect={onClickPhotoSelect}
          onDelete={onDelete}
          onPhotoDelete={onPhotoDelete}
        />
      ))}
      {!isStaticPage && (
        <Button
          variant="outlined"
          onClick={() => onAddPage(formatPageId)}
          sx={{ width: "100%", fontWeight: "bold" }}
        >
          <AddCircleOutlinedIcon fontSize="small" sx={{ mr: "8px" }} />
          ページを追加
        </Button>
      )}
    </div>
  );
};
interface PageAreaProps {
  page: ClientServiceReportPageResponse;
  pageIndex: number;
  formatPageIndex: number;
  isStaticPage: boolean;
  refsMap: { [key: string]: React.RefObject<HTMLDivElement> };
  selectedLayoutName: string;
  onChange: (newItem: ClientServiceReportItemResponse) => void;
  onDelete: (id: ClientServiceReportPageId) => void;
  onClickPhotoSelect: (item: ClientServiceReportItemResponse) => void;
  onPhotoDelete: (item: ClientServiceReportItemResponse) => void;
}

const PageArea = (props: PageAreaProps) => {
  const {
    page,
    pageIndex,
    formatPageIndex,
    isStaticPage,
    refsMap,
    selectedLayoutName,
    onChange,
    onDelete,
    onClickPhotoSelect,
    onPhotoDelete,
  } = props;
  const classes = styles();
  return (
    <>
      {isStaticPage ? (
        <div style={{ marginBottom: "24px" }}>
          <Typography className={classes.headingTitle} sx={{ mb: "8px" }}>
            書式
          </Typography>
          <Box className={classes.formatName}>{selectedLayoutName}</Box>
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "center", marginBottom: "24px" }}>
          <Typography sx={{ fontWeight: "500" }}>ページ{pageIndex + 1}</Typography>
          {pageIndex !== 0 && (
            <IconButton onClick={() => onDelete(page.id)}>
              <DeleteIcon color={theme.palette.grayScale[700]} size={16} />
            </IconButton>
          )}
        </div>
      )}
      {page.headings.map((heading, headingIndex) => {
        const key = isStaticPage
          ? `heading-${headingIndex}`
          : `heading-${pageIndex * ClientServiceReportItemsCount + headingIndex}`;
        const ref = refsMap[key];
        return (
          <HeadingArea
            key={headingIndex}
            ref={ref}
            heading={heading}
            headingIndex={headingIndex}
            formatPageIndex={formatPageIndex}
            pageIndex={pageIndex}
            onChange={onChange}
            onClickPhotoSelect={onClickPhotoSelect}
            onPhotoDelete={onPhotoDelete}
          />
        );
      })}
    </>
  );
};

interface HeadingAreaProps {
  heading: ClientServiceReportHeadingResponse;
  headingIndex: number;
  formatPageIndex: number;
  pageIndex: number;
  onChange: (newItem: ClientServiceReportItemResponse) => void;
  onClickPhotoSelect: (item: ClientServiceReportItemResponse) => void;
  onPhotoDelete: (item: ClientServiceReportItemResponse) => void;
}

const HeadingArea = forwardRef<HTMLDivElement, HeadingAreaProps>((props, ref) => {
  const { heading, headingIndex, onChange, onClickPhotoSelect, onPhotoDelete } = props;
  const classes = styles();
  return (
    <div key={headingIndex} style={{ marginBottom: "24px" }} ref={ref}>
      <Typography className={classes.headingTitle} sx={{ mb: "16px" }}>
        {heading.formatHeading.name}
      </Typography>
      <Stack style={{ marginLeft: "16px" }} spacing="16px">
        {heading.items.map((item, itemIndex) => (
          <ItemArea
            key={itemIndex}
            item={item}
            itemIndex={itemIndex}
            onChange={onChange}
            onClickPhotoSelect={onClickPhotoSelect}
            onPhotoDelete={onPhotoDelete}
          />
        ))}
      </Stack>
    </div>
  );
});
interface ItemAreaProps {
  item: ClientServiceReportItemResponse;
  itemIndex: number;
  onChange: (newItem: ClientServiceReportItemResponse) => void;
  onClickPhotoSelect: (item: ClientServiceReportItemResponse) => void;
  onPhotoDelete: (item: ClientServiceReportItemResponse) => void;
}

const ItemArea = (props: ItemAreaProps) => {
  const { item, itemIndex, onChange, onClickPhotoSelect, onPhotoDelete } = props;
  const [itemState, setItemState] = useState<ClientServiceReportItemResponse>(item);

  // 写真が変わった時に使用
  useEffect(() => {
    setItemState(item);
  }, [item]);

  /** 個々のItemのみで状態管理させることで、報告書全体(FormatPageArea)を再描画させないようにする  */
  const handleChangeValue = (value?: string) => {
    const newValue = value ?? "";
    const newItemState = { ...itemState, value: newValue };
    // Itemの見た目だけ変えてる
    setItemState(newItemState);
    // 親の状態（clientServiceReportRef）だけ変えてる
    onChange(newItemState);
  };

  const handleClickPhotoSelect = () => {
    onClickPhotoSelect({ ...itemState });
  };

  const handlePhotoDelete = () => {
    onPhotoDelete({ ...itemState });
  };
  return (
    <>
      <div
        key={itemIndex}
        style={{
          borderLeft: `1.5px solid ${theme.palette.primary.light}`,
          paddingLeft: "16px",
        }}
      >
        {itemState.formatItem.inputType !== "check_box" && (
          <Typography sx={{ fontSize: "14px", fontWeight: "500", mb: "8px" }}>
            {itemState.formatItem.name}
          </Typography>
        )}
        <InputFieldSwitcher
          state={itemState}
          onChange={handleChangeValue}
          onClick={handleClickPhotoSelect}
          onPhotoDelete={handlePhotoDelete}
        />
      </div>
    </>
  );
};
