import { Brand } from "data-access/repositories/brand";
import { UserId } from "data-access/repositories/user/user.dto";
import { PermissionSetId } from "features/company-settings/api/permission/permission_set.dto";

export type OutsourcingCostId = Brand<number, "OutsourcingCostId">;

export interface OutsourcingCost {
  id: OutsourcingCostId;
  quantity: number;
  unitType: {
    value: string;
    valueI18n: string;
  };
  unitPrice: number;
  amount: number;
  employee: {
    userId: UserId;
    name: string;
  };
}

export type OutsourcingCostIndexResponse = Array<OutsourcingCost>;

export interface OutsourcingCostCreateRequest {
  userId?: UserId;
  employee?: {
    name?: string;
    userEmail?: string;
    permissionSetId?: PermissionSetId | null;
    unitPricePerDay?: number;
  };
}

export interface OutsourcingCostUpdateRequest {
  quantity?: number;
  unitType?: string;
  unitPrice?: number;
  amount?: number;
}

export const initialOutSourcingCostCreateRequest: OutsourcingCostCreateRequest = {
  employee: {
    name: "",
    permissionSetId: null,
    userEmail: "",
    unitPricePerDay: 0,
  },
};
